import { useMemo, useState } from 'react';
import { UCCSearchOption } from './types';
import { useSearchPageQueryParams } from './useSearchPageQueryParams';

export const useViewAllRedirectUrl = (ucc1Number: string) => {
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const { rn, searchOption } = useSearchPageQueryParams();

  useMemo(() => {
    if (searchOption === UCCSearchOption.DocumentNumber) {
      setRedirectUrl(`?uccNumber=${ucc1Number}&searchOption=${searchOption}`);
    } else {
      setRedirectUrl(`?rn=${rn}&searchOption=${searchOption}`);
    }
  }, [searchOption, rn, ucc1Number]);

  return { redirectUrl };
};
