import {
  Grid,
  Box,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  useTheme,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import { IFormUI } from '../../../common/models';
import { FormDataUCC3 } from '../schema';
import { CollateralChangeType } from '../models';
import { useWatch } from 'react-hook-form';
import { StyledStartUccRadioButton } from '../../start-ucc-filing/styled-items/StyledStartUccFilingRadioButton';
import { StyledTextAreaInput } from '../../../common/components/inputs';

interface IAmendCollateralInformation extends IFormUI<FormDataUCC3> {
  collateralChangeTypesData: CollateralChangeType[] | null;
}

const AmendReleaseCollateral = ({
  control,
  formState: { errors },
  collateralChangeTypesData,
}: IAmendCollateralInformation) => {
  const [eventAmendReleaseCollateral, collateralChangeDescription] = useWatch({
    control,
    name: ['eventAmendReleaseCollateral', 'collateralChangeDescription'],
  });
  const theme = useTheme();

  return (
    <Box
      sx={{
        paddingY: theme.convert.pxToRem(28),
        paddingX: { xs: theme.convert.pxToRem(20), md: theme.convert.pxToRem(40) },
      }}
    >
      <Grid container direction="column" gap={2}>
        <Grid item xs={9}>
          <InputLabel htmlFor="collateralChangeType">
            <Typography variant="h5">
              Amendment/Release Collateral (Check box if applicable):
            </Typography>
          </InputLabel>
        </Grid>
        <Grid container>
          <Grid item xs={12} xl={9}>
            <Controller
              control={control}
              defaultValue=""
              name="collateralChangeType"
              render={({ field }) => (
                <FormControl fullWidth disabled={!eventAmendReleaseCollateral}>
                  <RadioGroup {...field}>
                    <Grid container columnGap={{ sm: 1, md: 5, lg: 10, xl: 18 }}>
                      {collateralChangeTypesData?.map((item: any) => {
                        return (
                          <Grid item key={item.id}>
                            <FormControlLabel
                              sx={{
                                '& .MuiFormControlLabel-label': {
                                  whiteSpace: 'nowrap',
                                },
                              }}
                              key={item.id}
                              value={item.id}
                              control={<StyledStartUccRadioButton />}
                              label={item.name}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <InputLabel htmlFor="collateralChangeDescription">
            <Typography variant="h5">Collateral Description:</Typography>
          </InputLabel>
        </Grid>
        <Grid container>
          <Grid item xs={12} xl={9}>
            <Controller
              control={control}
              name="collateralChangeDescription"
              render={({ field: { ref, ...field } }) => (
                <FormControl
                  error={!!errors.collateralChangeDescription?.message}
                  sx={{ width: ['98%', '98%', '85%', '95%', '82%'] }}
                >
                  <StyledTextAreaInput
                    disabled={!eventAmendReleaseCollateral}
                    placeholder="Enter collateral description"
                    {...field}
                    minRows={2}
                    error={!!errors.collateralChangeDescription?.message}
                  />
                  <Typography
                    sx={{
                      position: 'absolute',
                      right: theme.convert.pxToRem(10),
                      bottom: theme.convert.pxToRem(5),
                      fontSize: theme.typography.pxToRem(12),
                      color: theme.palette.grey[500],
                    }}
                  >
                    {collateralChangeDescription?.length ?? 0} / 15,000 characters
                  </Typography>
                </FormControl>
              )}
            />
            <FormHelperText error={!!errors.collateralChangeDescription?.message}>
              {errors.collateralChangeDescription?.message}
            </FormHelperText>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AmendReleaseCollateral;
