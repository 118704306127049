import PageWrapper from '../common/layouts/PageWrapper';
import { PageTitle } from '../common/constants/features-constants';
import {
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { formatDate, TimeZone } from '../common/helpers/format-date';
import { StyledUnderlineDownloadButtons } from '../features/download/StyledUnderlineDownloadButtons';
import Loader from '../common/components/loader';
import DisclaimerWrapper from '../common/components/DisclaimerWrapper';
import { Controller } from 'react-hook-form';
import { TextInput } from '../common/components/inputs';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as MagnifyingGlassIcon } from '../assets/icons/mag_glass_icon.svg';
import { useSearchPage } from '../features/search/use-search-page';
import { SearchResults } from '../features/search/SearchResults';
import { UCCSearchOption } from '../features/search/types';

const SEARCH_RULES_DOC_NAME = 'UCC_Debtor_Name_Compaction_and_Search_Rules.pdf';
const downloadSearchRules = () => window.open(SEARCH_RULES_DOC_NAME);

export const UCCSearch = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const {
    options,
    control,
    nextUrl,
    prevUrl,
    getUrl,
    option,
    throughDate,
    handleSubmit,
    searchResults,
    isFetchingSearchResults,
    lastKeywordWithNoResults,
    formState: { errors },
    hasFinishedInitialFetches,
  } = useSearchPage();

  return (
    <DisclaimerWrapper>
      <PageWrapper title={`UCC ${PageTitle.SEARCH}`} headTitle={PageTitle.SEARCH}>
        {!hasFinishedInitialFetches ? (
          <Box display={'flex'} justifyContent={'center'} marginTop={10}>
            <Loader color="blue" size={50} />
          </Box>
        ) : (
          <Box display={'flex'} flexDirection={'column'}>
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              justifyContent={{ xs: 'flex-start', md: 'flex-end' }}
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Typography
                borderRight={{ xs: 0, md: 2 }}
                fontWeight={{ xl: 700, lg: 600 }}
                variant={isMobile ? 'body2' : 'h5'}
                paddingRight={theme.typography.pxToRem(11)}
                borderColor={{ xs: 'black', md: theme.palette.text.disabled }}
              >
                {`Today's date: ${formatDate(new Date().toISOString())}`}
              </Typography>
              {throughDate && (
                <Typography
                  fontWeight={{ xl: 700, lg: 600 }}
                  variant={isMobile ? 'body2' : 'h5'}
                  paddingLeft={theme.typography.pxToRem(4)}
                >
                  {`UCC Filings Completed Through: ${formatDate(throughDate, TimeZone.EST)}`}
                </Typography>
              )}
            </Box>
            <Box
              paddingY={theme.typography.pxToRem(10)}
              marginTop={theme.typography.pxToRem(12)}
              marginBottom={theme.typography.pxToRem(16)}
              gap={{ xs: theme.typography.pxToRem(10), lg: theme.typography.pxToRem(20) }}
              paddingRight={{ xs: theme.typography.pxToRem(10), md: theme.typography.pxToRem(40) }}
              paddingLeft={{ xs: theme.typography.pxToRem(10), md: theme.typography.pxToRem(28) }}
              paddingTop={{ xs: theme.typography.pxToRem(24), lg: theme.typography.pxToRem(32) }}
              style={{
                backgroundColor: theme.palette.primary.light,
              }}
              display={{ lg: 'flex' }}
              flexWrap={'wrap'}
              alignItems={'center'}
            >
              <Box
                display={'flex'}
                alignItems={'center'}
                paddingBottom={theme.typography.pxToRem(19)}
              >
                <Typography variant={'body1'}>Select Search Type*</Typography>
              </Box>
              <Box
                flexGrow={1}
                display={'flex'}
                flexWrap={'wrap'}
                alignItems={'center'}
                justifyContent={'space-between'}
                gap={theme.typography.pxToRem(20)}
              >
                {options?.length && (
                  <Box
                    minWidth={{ xs: '100%', md: theme.typography.pxToRem(300) }}
                    maxWidth={{ md: 320 }}
                    flexGrow={1}
                  >
                    <Controller
                      control={control}
                      name="option"
                      render={({ field: { ref, ...field } }) => (
                        <FormControl fullWidth>
                          <Select
                            {...field}
                            id="option"
                            size="small"
                            inputRef={ref}
                            error={!!errors?.option?.message}
                            style={{ backgroundColor: theme.palette.background.default }}
                          >
                            {options.map((option) => {
                              return (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      )}
                    ></Controller>
                    <FormHelperText error>{errors.option?.message ?? ' '}</FormHelperText>
                  </Box>
                )}
                <Box flexGrow={1} maxWidth={{ lg: 500 }}>
                  <Controller
                    name="keyword"
                    control={control}
                    render={({ field: { ref, ...field } }) => (
                      <TextInput
                        {...field}
                        size={'small'}
                        inputRef={ref}
                        error={!!errors.keyword?.message}
                        placeholder={
                          option === UCCSearchOption.DocumentNumber
                            ? 'Enter UCC number'
                            : 'Enter debtor name'
                        }
                        onKeyUp={(e) => {
                          e.key === 'Enter' && handleSubmit();
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton edge="end" aria-label="search" onClick={handleSubmit}>
                                <MagnifyingGlassIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  <FormHelperText style={{ textTransform: 'none' }} error>
                    {errors.keyword?.message ?? ' '}
                  </FormHelperText>
                </Box>
              </Box>
            </Box>
            <Box marginBottom={theme.typography.pxToRem(20)}>
              <Typography variant={isMobile ? 'h3' : 'h4'} fontWeight={{ xl: 600, xs: 600 }}>
                Click{' '}
                <StyledUnderlineDownloadButtons onClick={downloadSearchRules}>
                  <Typography
                    variant={isMobile ? 'h3' : 'h4'}
                    fontWeight={{ xl: 400, xs: 600 }}
                    color={'inherit'}
                  >
                    here
                  </Typography>
                </StyledUnderlineDownloadButtons>{' '}
                to see the details of the search logic used on this website. Use Previous/Next links
                to scroll through search results. Opening/Viewing a filing will position that filing
                at the top of the search results list.
              </Typography>
            </Box>
            <SearchResults
              getUrl={getUrl}
              nextUrl={nextUrl}
              prevUrl={prevUrl}
              results={searchResults}
              lastKeywordWithNoResults={lastKeywordWithNoResults}
              isFetching={isFetchingSearchResults}
            />
          </Box>
        )}
      </PageWrapper>
    </DisclaimerWrapper>
  );
};
