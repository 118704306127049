import { Box, Button, Grid, useTheme } from '@mui/material';
import { FC } from 'react';
import { ReactComponent as ArrowRight } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/arrow-left.svg';
import { useNavigate } from 'react-router-dom';

interface NavigationProps {
  prevUrl: string | null;
  nextUrl: string | null;
}

const Navigation: FC<NavigationProps> = ({ nextUrl, prevUrl }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Grid
      container
      display={'flex'}
      justifyContent={'space-between'}
      marginTop={theme.convert.pxToRem(11)}
    >
      <Grid item>
        <Box>
          <Button
            disabled={!prevUrl}
            onClick={() => navigate(prevUrl ?? '')}
            startIcon={<ArrowLeft fill={prevUrl ? '#2E73DC' : '#909090'} />}
          >
            Previous
          </Button>
        </Box>
      </Grid>
      <Grid item>
        <Box>
          <Button
            disabled={!nextUrl}
            onClick={() => navigate(nextUrl ?? '')}
            endIcon={<ArrowRight fill={nextUrl ? '#2E73DC' : '#909090'} />}
          >
            Next
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Navigation;
