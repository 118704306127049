export interface SearchOption {
  id: string;
  name: string;
}

export interface FileDataInterface {
  uccNumber: string;
  status: string;
  fileDate: string;
  expirationDate: string;
  filingsCompletedThrough: string;
  summary: string;
  filingEvents: number;
  fileImageExists: boolean;
  ucc1Number: string;
  isTransUtil: boolean;
}

export interface SecuredPartiesInterface {
  secureds: FilingDetailEntityInterface[];
  securedPartiesTotalCount?: number;
}

export interface DebtorPartiesInterface {
  debtors: FilingDetailEntityInterface[];
  debtorPartiesTotalCount: number;
}

export interface DocumentImagesInterface {
  documentType: string;
  documentPagesCount: number;
  numberOfPagesInAllAssociatedForms: number;
}

export interface FilingDetailsNavigationInterface {
  prevRowNumber: string;
  nextRowNumber: string;
}

export interface FilingDetailEntityInterface {
  name: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface FileDetailsInterface
  extends FileDataInterface,
    SecuredPartiesInterface,
    DebtorPartiesInterface,
    DocumentImagesInterface,
    FilingDetailsNavigationInterface {}

export interface SearchResultsFetchParams {
  text: string;
  rowNumber?: number;
  searchOption: string;
}

export interface SingleDebtorSearchResult {
  city: string;
  name: string;
  state: string;
  status: string;
  zipCode: string;
  address: string;
  rowNumber: number;
  uccNumber: string;
}

export interface DebtorSearchResponse {
  nextRowNumber: number;
  previousRowNumber: number;
  debtors: SingleDebtorSearchResult[];
}

export enum UCCSearchOption {
  DocumentNumber = 'DocumentNumber',
  FiledActualDebtorNameList = 'FiledActualDebtorNameList',
  FiledCompactDebtorNameList = 'FiledCompactDebtorNameList',
  LapsedActualDebtorNameList = 'LapsedActualDebtorNameList',
  LapsedCompactDebtorNameList = 'LapsedCompactDebtorNameList',
  FiledAndLapsedActualDebtorNameList = 'FiledAndLapsedActualDebtorNameList',
  FiledAndLapsedCompactDebtorNameList = 'FiledAndLapsedCompactDebtorNameList',
}

export enum ActionNameType {
  Debtor = 'D',
  Secured = 'S',
}

export interface FilingAction {
  actionName: string;
  actionNameType: ActionNameType | null;
  originalEntity: FilingDetailEntityInterface;
  newEntity: FilingDetailEntityInterface;
}

export interface FilingHistoryInterface {
  actions: FilingAction[];
  actionsCount: number;
  date: string;
  documentNumber: string;
  fileImageExists: false;
  pages: number;
  type: string;
}
