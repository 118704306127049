import DisclaimerWrapper from '../common/components/DisclaimerWrapper';
import PageWrapper from '../common/layouts/PageWrapper';
import { useDebtorPartiesPage } from '../features/search/debtor-parties/use-debtor-parties-page';
import { useSearchPageQueryParams } from '../features/search/useSearchPageQueryParams';
import { UCCSearchOption } from '../features/search/types';
import { PageTitle } from '../common/constants/features-constants';
import DebtorSecuredList from '../features/search/DebtorSecuredList';

export const UCCSearchDetailsDebtorParties = () => {
  const { uccNumber, rn, searchOption } = useSearchPageQueryParams();
  const { fileData, isLoading } = useDebtorPartiesPage(
    searchOption ?? UCCSearchOption.DocumentNumber,
    uccNumber ?? undefined,
    rn
  );

  return (
    <DisclaimerWrapper>
      <PageWrapper title="UCC Search" headTitle={PageTitle.DEBTOR_LISTING}>
        <DebtorSecuredList
          title="Debtor Parties For"
          isLoading={isLoading}
          uccNumber={uccNumber ?? fileData?.uccNumber}
          data={fileData?.debtors}
        />
      </PageWrapper>
    </DisclaimerWrapper>
  );
};
