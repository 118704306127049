import PageWrapper from '../common/layouts/PageWrapper';
import { UCCSearchOption } from '../features/search/types';
import DisclaimerWrapper from '../common/components/DisclaimerWrapper';
import { useSearchPageQueryParams } from '../features/search/useSearchPageQueryParams';
import { useSecuredPartiesPage } from '../features/search/secured-parties/use-secured-parties-page';
import { PageTitle } from '../common/constants/features-constants';
import DebtorSecuredList from '../features/search/DebtorSecuredList';

export const UCCSearchDetailsSecuredParties = () => {
  const { uccNumber, rn, searchOption } = useSearchPageQueryParams();
  const { fileData, isLoading } = useSecuredPartiesPage(
    searchOption ?? UCCSearchOption.DocumentNumber,
    uccNumber ?? undefined,
    rn
  );
  return (
    <DisclaimerWrapper>
      <PageWrapper title="UCC Search" headTitle={PageTitle.SECURED_LISTING}>
        <DebtorSecuredList
          title="Secured Parties For"
          isLoading={isLoading}
          uccNumber={uccNumber ?? fileData?.uccNumber}
          data={fileData?.secureds}
        />
      </PageWrapper>
    </DisclaimerWrapper>
  );
};
