import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Grid, Typography, useTheme } from '@mui/material';

import Loader from '../common/components/loader';
import {
  StyledNavigationTypography,
  StyledTableContainer,
  StyledTableContentGrid,
  StyledTableHeadingGrid,
} from '../features/search/details/StyledComponents';
import PageWrapper from '../common/layouts/PageWrapper';
import { formatDate, TimeZone } from '../common/helpers/format-date';
import Navigation from '../features/search/filing-history/Navigation';
import DisclaimerWrapper from '../common/components/DisclaimerWrapper';
import { useFileImage } from '../features/search/details/use-file-image';
import { useSearchDataFetch } from '../features/search/useSearchDataFetch';
import { ReactComponent as BackArrow } from '../assets/icons/arrow-back.svg';
import SearchResultsHeading from '../features/search/details/SearchResultsHeading';
import { useSearchPageQueryParams } from '../features/search/useSearchPageQueryParams';

import { PageTitle } from '../common/constants/features-constants';
import { getFilingHistory } from '../features/search/search.services';
import {
  uccSearchDetailsFilingHistoryURL,
  uccSearchDetailsPageURL,
} from '../common/constants/routes';
import { gridLoadingBackdrop, itemDeviderBorder } from '../common/constants/colors';
import {
  ActionNameType,
  FilingAction,
  FilingDetailEntityInterface,
} from '../features/search/types';

const formatEntity = (e: FilingDetailEntityInterface | null) =>
  e ? [e.name, e.address, e.city, e.state, e.zipCode].filter(Boolean).join(', ') : null;

const formatAction = (a: FilingAction) => {
  const isChangeAction = a.actionNameType && a.newEntity && a.originalEntity;
  const newEntityFormatted = formatEntity(a.newEntity);
  const oldEntityFormatted = formatEntity(a.originalEntity);
  let actorName = null;

  if (isChangeAction) {
    actorName = a.actionNameType === ActionNameType.Debtor ? 'debtor' : 'secured';
  }

  return { newEntityFormatted, oldEntityFormatted, actorName };
};

const renderEntity = (formattedContent: string | null, isNew: boolean, actorName: string | null) =>
  formattedContent ? (
    <>
      {actorName && <Typography>{`${isNew ? 'New' : 'Old'} ${actorName} is:`}</Typography>}
      <Typography>{formattedContent}</Typography>
    </>
  ) : null;

const headerElements = ['Document Number', 'Type', 'Date', 'Pages', 'Actions'];
export const UCCSearchDetailsFilingHistory = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { onDownloadClick } = useFileImage();
  const { uccNumber, pageNumber, getUrl, searchOption, text } = useSearchPageQueryParams();
  const {
    data: fileData,
    isFetching: isLoading,
    doFetch,
    meta: metaData,
  } = useSearchDataFetch(() => getFilingHistory(uccNumber, pageNumber), true, true, true);

  const handleBackClick = () => {
    searchOption === 'DocumentNumber'
      ? navigate(getUrl(uccSearchDetailsPageURL, { page: null, uccNumber: text }))
      : navigate(getUrl(uccSearchDetailsPageURL, { page: null }));
  };

  useEffect(() => {
    doFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const EventHeader = () => {
    return (
      <Grid container direction="row">
        {headerElements.map((headerEl, index) => (
          <StyledTableHeadingGrid
            item
            xs={index === 0 ? 3 : index === 1 ? 1.8 : 2.4}
            key={headerEl}
          >
            <Typography fontWeight={600} whiteSpace={'nowrap'}>
              {headerEl}
            </Typography>
          </StyledTableHeadingGrid>
        ))}
      </Grid>
    );
  };

  const createUrl = (pageNumber: string, increment: number) => {
    const currentPageNumber = parseInt(pageNumber);
    const newPageNumber = isNaN(currentPageNumber) ? 1 : currentPageNumber + increment;

    return getUrl(uccSearchDetailsFilingHistoryURL, { page: newPageNumber.toString() });
  };

  if (isLoading && !fileData) {
    return (
      <Box display={'flex'} justifyContent={'center'} marginTop={10}>
        <Loader color="blue" size={50} />
      </Box>
    );
  }

  return (
    <DisclaimerWrapper>
      <PageWrapper title="UCC Search" headTitle={PageTitle.FILING_HISTORY}>
        <Box marginBottom={theme.convert.pxToRem(20)}>
          <Button onClick={handleBackClick} startIcon={<BackArrow />}>
            Back
          </Button>
        </Box>

        <StyledTableContainer sx={{ width: '100%', overflowX: 'auto' }}>
          {isLoading && (
            <Box
              zIndex={1}
              width={'100%'}
              height={'100%'}
              display={'flex'}
              position={'absolute'}
              alignItems={'center'}
              justifyContent={'center'}
              style={{ backgroundColor: gridLoadingBackdrop }}
            >
              <Loader color="blue" size={50} />
            </Box>
          )}
          <Grid
            container
            style={{
              minWidth: theme.convert.pxToRem(1140),
            }}
          >
            <SearchResultsHeading
              content={`Filing History/Filing Events For: ${fileData ? uccNumber : ''}`}
            />

            <Grid container direction="row" alignItems="center">
              <Grid container direction="column">
                {fileData?.map((event, index) => (
                  <Box key={event.documentNumber + index}>
                    <EventHeader />
                    <Grid container direction="row" key={index}>
                      <StyledTableContentGrid item xs={3}>
                        {event.fileImageExists ? (
                          <Button onClick={() => onDownloadClick(event.documentNumber)}>
                            <StyledNavigationTypography>
                              {event.documentNumber}
                            </StyledNavigationTypography>
                          </Button>
                        ) : (
                          <Typography whiteSpace={'nowrap'}>
                            {event.documentNumber} (No Image On File)
                          </Typography>
                        )}
                      </StyledTableContentGrid>
                      <StyledTableContentGrid item xs={1.8}>
                        <Typography>{event.type}</Typography>
                      </StyledTableContentGrid>
                      <StyledTableContentGrid item xs={2.4}>
                        <Typography>{formatDate(event.date, TimeZone.EST)}</Typography>
                      </StyledTableContentGrid>
                      <StyledTableContentGrid item xs={2.4}>
                        <Typography>{event.pages}</Typography>
                      </StyledTableContentGrid>
                      <StyledTableContentGrid item xs={2.4}>
                        <Typography>{event.actionsCount}</Typography>
                      </StyledTableContentGrid>
                    </Grid>
                    <Grid container alignItems="center">
                      <StyledTableContentGrid item xs={12}>
                        <Typography fontWeight={600}>Actions</Typography>
                      </StyledTableContentGrid>
                    </Grid>
                    {event.actions.map((action, index) => {
                      const { newEntityFormatted, oldEntityFormatted, actorName } =
                        formatAction(action);
                      return (
                        <Grid
                          container
                          direction={'row'}
                          paddingBottom={theme.convert.pxToRem(24)}
                          borderTop={`1px solid ${itemDeviderBorder}`}
                          key={event.actions.length + index}
                        >
                          <StyledTableContentGrid item xs={4.8}>
                            <Typography>{`${index + 1}. ${action.actionName}`}</Typography>
                          </StyledTableContentGrid>
                          <StyledTableContentGrid
                            item
                            xs={5}
                            padding={`${theme.convert.pxToRem(12)} ${theme.convert.pxToRem(40)}`}
                          >
                            {renderEntity(oldEntityFormatted, false, actorName)}
                            {renderEntity(newEntityFormatted, true, actorName)}
                          </StyledTableContentGrid>
                        </Grid>
                      );
                    })}
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </StyledTableContainer>
        {uccNumber && pageNumber && fileData && metaData && (
          <Navigation
            lastPage={Math.ceil(metaData.totalCount / metaData.pageSize)}
            prevUrl={createUrl(pageNumber, -1)}
            nextUrl={createUrl(pageNumber, 1)}
          />
        )}
      </PageWrapper>
    </DisclaimerWrapper>
  );
};
