import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import Ucc1Ui from './ucc1-ui';
import { expandActionLabels } from '../../common/constants/features-constants';
import { routes } from '../../common/constants';
import { Accordion, accordionCollapseAllState, accordionExpandAllState } from './constants';
import { type FormDataUCC1, saveSchema, submitSchema } from './schema';
import { cancelUCC1, deleteUCC1DebSec, updateUCC1 } from './ucc1.services';
import { useModal, useUserInfo } from '../../common/context';
import { useSnackbar } from '../../common/notification';
import useUnsavedChangesModal from '../../common/components/modals/unsaved-changes/useUnsavedChangesModal';
import { DebSecModalContent } from './deb-sec-modal';
import { StyledActionButton } from '../../common/global-styles';
import { parseErrorMessages, uppercaseSelectedDataFields } from '../../common/helpers';
import Loader from '../../common/components/loader';

import {
  ActionType,
  IDebSecModal,
  IFilingAlert,
  IHandleOnRemove,
  IOnSuccess,
  IUCC1Props,
  RemoveDebSec,
} from './models';
import { UnsavedHandlerContext } from '../shared/ucc/unsaved-handler-context/UnsavedHandlerContext';
import { useAuthedForm } from '../../common/hooks/useAuthedForm';
import { useHasInvalidFields } from '../../common/hooks/useHasInvalidFields';
import { useFilingFormCleanup } from '../shared/ucc/useFilingFormCleanup';
import { useBlocker } from '../../common/hooks/usePrompt';

const UCC1 = ({
  filingId,
  debtors,
  securedParties,
  dataUCC1,
  attachments,
  updateAttachmentsList,
  updateDebSecList,
  designationTypes,
  docStampTax,
  financingTypesData,
  debtorTypesData,
  debtorFilingTypesData,
  isAttachmentListLoading,
}: IUCC1Props) => {
  const navigate = useNavigate();
  const { Snack } = useSnackbar();
  const { getCartDetails, setDelayFilingsFetch } = useUserInfo();
  const { handleUnsavedChanges } = useUnsavedChangesModal();
  const { handleModalOpen, handleModalClose, handleSetModalActions } = useModal();
  const [filingAlerts, setFilingAlerts] = useState<IFilingAlert[] | null>(null);
  const [isOffensiveLanguageOverrided, setIsOffensiveLanguageOverrided] = useState(false);
  const [isSavingUCC1, setIsSavingUCC1] = useState(false);
  const [accordionState, setAccordionState] = useState(accordionExpandAllState);
  const [isLoading, setIsLoading] = useState(false);
  const isPublishValidationRef = useRef(true);
  const {
    setIsSaved,
    setIsCompleted,
    setIsCanceled,
    hasPendingUploads,
    isCanceled,
    isSaved,
    isCompleted,
  } = useContext(UnsavedHandlerContext);

  const {
    handleSubmit,
    formState,
    control,
    setFocus,
    reset,
    setValue,
    getValues,
    watch,
    clearErrors,
    resetField,
  } = useAuthedForm({
    isPublishValidationRef: isPublishValidationRef,
    defaultValues: {
      ackContactName: dataUCC1?.ackContactName ?? '',
      ackContactPhone: dataUCC1?.ackContactPhone ?? '',
      ackContactEmail: dataUCC1?.ackContactEmail ?? '',
      financingStatement: dataUCC1?.financingStatement ?? '',
      altDesignationType: dataUCC1?.altDesignationType,
      docStampTaxType: dataUCC1?.docStampTaxType,
      filerRefData: dataUCC1?.filerRefData ?? '',
      hasAddendum: dataUCC1?.hasAddendum,
      miscellaneous: dataUCC1?.miscellaneous ?? '',
      financingStatementCoverageType: dataUCC1?.financingStatementCoverageType,
      realEstateDescription: dataUCC1?.realEstateDescription ?? '',
      recordOwnerNameAddress: dataUCC1?.recordOwnerNameAddress ?? '',
      additionalCollateralDescription: dataUCC1?.additionalCollateralDescription ?? '',
      debtorType: dataUCC1?.debtorType,
      debtorFilingType: dataUCC1?.debtorFilingType,
    },
    submitSchema,
    saveSchema,
  });

  const hasInvalidFields = useHasInvalidFields(formState);

  const doFormCancel = useCallback(
    async (withRedirection = false) => {
      if (dataUCC1 && dataUCC1.id) {
        setIsCanceled(true);
        setIsSaved(false);
        setIsCompleted(false);
        const res = await cancelUCC1(filingId);
        if (res.notOk || Object.keys(res).length === 0) {
          Snack.error(res.messages[0] || 'Something went wrong, please try again later');
        }
      }
      getCartDetails();
      withRedirection && navigate('/onlinefiling/dashboard');
    },
    [dataUCC1, filingId, setIsCanceled, setIsCompleted, setIsSaved]
  );

  const shouldCleanOnLeave = !isSaved && !isCompleted && !isCanceled;
  useEffect(() => {
    setDelayFilingsFetch(shouldCleanOnLeave);
  }, [setDelayFilingsFetch, shouldCleanOnLeave]);

  const [showBlocker, setShowBlocker] = useState<boolean>(false);

  useFilingFormCleanup(async () => {
    await doFormCancel(false);
    setDelayFilingsFetch(false);
  }, shouldCleanOnLeave && !showBlocker);

  useBlocker(
    showBlocker,
    useMemo(() => {
      return async () => {
        await doFormCancel();
        setDelayFilingsFetch(false);
      };
    }, [doFormCancel, setDelayFilingsFetch])
  );
  const handleFormCancel = useCallback(async () => {
    if (isCanceled || isSaved || isCompleted) {
      getCartDetails();
      return navigate('/onlinefiling/dashboard');
    }
    if (!formState.isDirty && filingAlerts === null && !hasPendingUploads) {
      doFormCancel(true);
      Snack.info('Action canceled.');
    } else {
      handleUnsavedChanges({
        onProceed: async () => {
          doFormCancel(true);
        },
      });
    }
  }, [formState.isDirty, isSaved, isCompleted]);

  const handleAccordionState = (accordion: Accordion) =>
    setAccordionState((prevState) =>
      prevState.map((state, i) => (i === accordion ? !state : state))
    );

  const handleCheckboxAccordionState = (accordion: Accordion) => {
    if (!accordionState[accordion]) {
      handleAccordionState(accordion);
    }
  };

  const expandActionLabel = accordionState.every(Boolean) ? 'Collapse All' : 'Expand All';
  const expandAction = () => {
    if (expandActionLabel === expandActionLabels.CollapseAll) {
      setAccordionState(accordionCollapseAllState);
    } else {
      setAccordionState(accordionExpandAllState);
    }
  };

  const handleOnComplete = (e: React.SyntheticEvent) => {
    e.preventDefault();
    isPublishValidationRef.current = true;
    setIsCompleted(true);

    if (hasInvalidFields) {
      Snack.error(parseErrorMessages());
    }

    handleSubmit(async (data: FormDataUCC1, e) => {
      if (debtors.length === 0) {
        return Snack.error('You must have at least one debtor to complete the filling.');
      } else if (securedParties.length === 0) {
        return Snack.error('You must have at least one secured party to complete the filling.');
      }

      if (!data.hasAddendum) {
        resetAddendumValues();
      }

      const payload = {
        ...uppercaseSelectedDataFields(data, [
          'ackContactName',
          'ackContactEmail',
          'financingStatement',
          'filerRefData',
          'additionalCollateralDescription',
          'miscellaneous',
          'realEstateDescription',
          'recordOwnerNameAddress',
          'additionalCollateralDescription',
        ]),
        actionType: ActionType.Complete,
        isOffensiveLanguageOverrided,
      };
      const res = await updateUCC1({ filingId, payload });
      const currentValues = getValues();
      reset(currentValues, {
        keepValues: true,
        keepDirty: false,
        keepTouched: false,
        keepIsSubmitted: false,
        keepSubmitCount: false,
      });
      if (!res.notOk) {
        Snack.success('UCC1 filing successfully completed.');
        setFilingAlerts(null);
        setIsOffensiveLanguageOverrided(false);
        setDelayFilingsFetch(false);
        navigate(routes.cart);
      } else if (res.payload?.filingAlerts !== null && res.payload?.filingAlerts?.length > 0) {
        setFilingAlerts(res.payload?.filingAlerts);
      } else {
        Snack.error(parseErrorMessages(res.messages));
      }
      setIsOffensiveLanguageOverrided(false);
    })();
  };

  const handleOnSave = (e: React.SyntheticEvent) => {
    e.preventDefault();
    isPublishValidationRef.current = false;
    setIsSaved(true);

    if (hasInvalidFields) {
      Snack.error(parseErrorMessages());
    }

    handleSubmit(async (data: FormDataUCC1, evt) => {
      if (!data.hasAddendum) {
        resetAddendumValues();
      }

      const payload = {
        ...uppercaseSelectedDataFields(data, [
          'ackContactName',
          'ackContactEmail',
          'financingStatement',
          'filerRefData',
          'additionalCollateralDescription',
          'miscellaneous',
          'realEstateDescription',
          'recordOwnerNameAddress',
          'additionalCollateralDescription',
        ]),
        actionType: ActionType.Save,
        debtors,
        secured: securedParties,
      };
      setIsSavingUCC1(true);
      const res = await updateUCC1({ filingId, payload });
      const currentValues = getValues();
      reset(currentValues, {
        keepValues: true,
        keepDirty: false,
        keepTouched: false,
        keepIsSubmitted: false,
        keepSubmitCount: false,
      });
      if (!res.notOk) {
        Snack.success('UCC1 filing successfully saved.');
      } else {
        Snack.error(parseErrorMessages(res.messages));
      }

      setIsSavingUCC1(false);
      isPublishValidationRef.current = true;
    })();
  };

  const resetAddendumValues = () => {
    setValue('miscellaneous', '');
    setValue('realEstateDescription', '');
    setValue('recordOwnerNameAddress', '');
    setValue('additionalCollateralDescription', '');
    setValue('financingStatementCoverageType', null);
    setValue('debtorFilingType', null);
    setValue('debtorType', null);
  };

  useEffect(() => {
    setFocus('ackContactName');
  }, []);

  const onSuccess = ({ filingId, isEdit, partyName }: IOnSuccess) => {
    updateDebSecList({ filingId });
    handleModalClose();
    Snack.success(`${partyName} successfully ${isEdit ? 'updated' : 'added'}.`);
  };

  const openDebSecModal = ({ partyName, isEdit, debsecId, type }: IDebSecModal) => {
    handleModalOpen({
      title: `${isEdit ? 'Edit' : 'Add'} ${partyName}`,
      modalContent: (
        <DebSecModalContent
          partyName={partyName}
          isEdit={isEdit}
          debsecId={debsecId}
          filingId={filingId}
          type={type}
          onSuccess={onSuccess}
        />
      ),
      modalActions: (
        <>
          <StyledActionButton
            onClick={(e) => {
              handleModalClose(e);
              Snack.info('Action canceled.');
            }}
            variant="outlined"
          >
            Cancel
          </StyledActionButton>
          <Box sx={{ width: 37 }} />
          <StyledActionButton form="debsec-modal-form" type="submit" variant="contained" disabled>
            Save
          </StyledActionButton>
        </>
      ),
    });
  };

  const handleOnRemoveCancel = (evt: React.SyntheticEvent) => {
    handleModalClose(evt);
    Snack.info('Action canceled.');
  };

  const handleOnRemove = async ({ evt, filingId, debsecId, partyName }: IHandleOnRemove) => {
    setIsLoading(true);
    if (filingId) {
      const { notOk, messages } = await deleteUCC1DebSec({ filingId, debsecId });
      if (!notOk) {
        Snack.success(`${partyName} is successfully removed.`);
        updateDebSecList({ filingId });
      } else {
        Snack.error(parseErrorMessages(messages));
      }
    } else {
      Snack.error('Missing filing ID number.');
    }
    setIsLoading(false);
    handleModalClose(evt);
  };

  const removeDebSec = ({ partyName, debsecId, filingId }: RemoveDebSec) => {
    handleModalOpen({
      title: `Remove ${partyName}`,
      modalContent: (
        <Typography>{`Are you sure you want to remove this ${partyName.toLowerCase()}?`}</Typography>
      ),
      modalActions: (
        <>
          <StyledActionButton onClick={(evt) => handleOnRemoveCancel(evt)} variant="outlined">
            No, cancel
          </StyledActionButton>
          <Box sx={{ width: 37 }} />
          <StyledActionButton
            autoFocus
            onClick={(evt) => handleOnRemove({ evt, debsecId, filingId, partyName })}
            variant="contained"
          >
            Yes, remove
          </StyledActionButton>
        </>
      ),
    });
  };

  useEffect(() => {
    handleSetModalActions(
      <>
        <StyledActionButton onClick={(evt) => handleOnRemoveCancel(evt)} variant="outlined">
          No, cancel
        </StyledActionButton>
        <Box sx={{ width: 37 }} />
        <StyledActionButton
          autoFocus
          variant="contained"
          startIcon={isLoading ? <Loader /> : null}
          disabled={isLoading}
        >
          {isLoading ? 'Yes, removing' : 'Yes, remove'}
        </StyledActionButton>
      </>
    );
  }, [isLoading]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (isSaved) setIsSaved(false);
      if (isCompleted) setIsCompleted(false);
      if (isCanceled) setIsCanceled(false);
    });
    return () => subscription.unsubscribe();
  }, [watch, formState.isDirty]);

  return (
    <Ucc1Ui
      formState={formState}
      isCompleteFormValid={
        formState.isValid && isPublishValidationRef.current && !hasPendingUploads
      }
      control={control}
      setValue={setValue}
      clearErrors={clearErrors}
      resetField={resetField}
      filingId={filingId}
      filingAlerts={filingAlerts}
      isOffensiveLanguageOverrided={isOffensiveLanguageOverrided}
      isSavingUCC1={isSavingUCC1}
      setIsOffensiveLanguageOverrided={setIsOffensiveLanguageOverrided}
      expandAction={expandAction}
      expandActionLabel={expandActionLabel}
      handleAccordionState={handleAccordionState}
      handleCheckboxAccordionState={handleCheckboxAccordionState}
      handleOnComplete={handleOnComplete}
      handleOnSave={handleOnSave}
      accordionState={accordionState}
      debtors={debtors}
      securedParties={securedParties}
      attachments={attachments}
      handleFormCancel={handleFormCancel}
      removeDebSec={removeDebSec}
      openDebSecModal={openDebSecModal}
      updateAttachmentsList={updateAttachmentsList}
      designationTypes={designationTypes}
      docStampTax={docStampTax}
      financingTypesData={financingTypesData}
      debtorTypesData={debtorTypesData}
      debtorFilingTypesData={debtorFilingTypesData}
      getValues={getValues}
      isDirty={formState.isDirty}
      isSaved={isSaved}
      isCanceled={isCanceled}
      isCompleted={isCompleted}
      hasPendingUploads={hasPendingUploads}
      isAttachmentListLoading={isAttachmentListLoading}
      doFormCancel={doFormCancel}
      showBlocker={showBlocker}
      setShowBlocker={setShowBlocker}
    />
  );
};

export default UCC1;
