import { getFileDetails } from '../search.services';
import { useSearchDataFetch } from '../useSearchDataFetch';

export const useSecuredPartiesPage = (
  searchOptionsType: string,
  document?: string,
  rn?: string
) => {
  const { data: fileData, isFetching: isLoading } = useSearchDataFetch(
    () => getFileDetails(searchOptionsType, document, rn),
    true
  );

  return { fileData, isLoading };
};
