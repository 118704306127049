import { Typography, useTheme } from '@mui/material';
import ListAccordions from '../ListAccordions';
import { feesPageURL, formsPageURL, uccSearchPageURL } from '../../constants/routes';
import { StyledInheritLink } from '../../../features/start-ucc-filing/styled-items/StyledInheritButton';

interface ListItem {
  text: string | React.ReactNode;
  children?: ListItem[];
}

const FaqAccordionsHelp = () => {
  const theme = useTheme();

  const inquiryFunctionsExplanationsAndExamples = [
    {
      text: 'UCC Filing Detail By Document Number',
      children: [
        {
          text: 'This transaction provides detailed information on the actual filing of the UCC filing requested by document number. If the requested document is not found, an error message will be displayed.',
        },
        {
          text: 'Example: To inquire by UCC document number, type the 12-digit document number in the Name/Document Number field, then press the "Search" button. The screen that appears will contain detailed information about the filing, including document number, secured party and debtor name and address, file date, expiration date, process through date, etc. (You can also reach this screen by choosing one of the names listed on the UCC name list).',
        },
      ],
    },
    {
      text: 'UCC Filing Inquiry By Debtor Name',
      children: [
        {
          text: "These transactions provide a list of UCC filings on the Florida Secured Transaction Registry beginning with the debtor name that is closest to the key entered. This list also includes the document number and type of each record. There are several inquiry functions available using the Debtor's Name, all of these inquiry functions will provide the user with an alphabetic listing beginning with the name closest to the key entered. On the name list the user will see the debtor name, address, date of birth or corporate federal employee identification number (optional), and the status (filed or lapsed). To obtain more detailed information about the filing, click on the name you wish to view. The UCC detail screen will be displayed. If additional filing history is available, the message 'View Filing History' will appear on the detail screen. The information is as described in UCC Filing Detail by Document Number.",
        },
        {
          text: 'All inquires actual, compact, active, inactive, or active/inactive, UCC will display a name list. However, using the different inquiry options will determine how and what type of filings appear on that list.',
        },
        {
          text: "Example: If you are looking for one (1) particular filing and you know the debtor's actual name and the filing is active you would only need to inquire under actual function-list ordered by 'active' debtor name. For more detailed information about the difference between using actual and compact see How/When To Use Actual Or Compact Inquiry.",
        },
      ],
    },
    {
      text: 'How/When To Use Actual Or Compact Inquiry',
      children: [
        {
          text: 'Actual Name',
          children: [
            {
              text: 'Actual name is used to group entries in the same manner as the telephone directory. All individuals with the same last name would be grouped together in order by their first name, then middle initial. Corporate names beginning with the same word will follow the individual names.',
            },
            {
              text: 'To access the alphabetic name listing using actual name, the name must be keyed with exact spelling of the debtor name as entered on the original UCC1 filing. Actual name inquiries for individuals are made by typing the last name followed by the first name with nothing between the names. Actual corporate inquiries are made by typing the company name exactly as it appears on the filing.',
            },
            {
              text: (
                <>
                  <Typography>
                    Example: For inquiries on individual debtor &apos;ARTHUR BEACH&apos;, type
                    &apos;BEACHARTHUR&apos; in the Name field, then press the &quot;Search&quot;
                    button. A Resulting Name List Might Be:
                  </Typography>
                  <Typography marginBottom={2}>
                    Beach Arthur <br />
                    Beach Tom <br />
                    Beache Fred
                  </Typography>
                  <Typography>
                    For inquiries on corporate debtor &apos;ABC Fence Company&apos;, type &apos;ABC
                    Fence Company&apos; in the Name field, then press the &quot;Search&quot; button.
                  </Typography>
                </>
              ),
            },
          ],
        },
        {
          text: <Typography fontWeight={600}>Compact Name</Typography>,
          children: [
            {
              text: 'This inquiry function allows users to access the same type of name list as with the actual name functions. This option is sometimes helpful if you are unsure of the exact spelling. Names stored by the compact name are displayed so that all variations of names that are alike are grouped together. When searching by compact name, you can enter the name in and the compaction routine will eliminate spaces, common words ( a, and, the) and punctuation, etc.',
            },
            {
              text: (
                <>
                  <Typography marginBottom={2}>
                    Example: For inquiries on corporate debtor &apos;HIS AND HER
                    INFORMATION&apos;,type &apos;HIS AND HER INFORMATION&apos; in the Name field,
                    then press the &quot;Search&quot; button.
                  </Typography>
                  <Typography marginBottom={2}>A Resulting Name List Might Be:</Typography>
                  <Typography marginBottom={2}>
                    His And Her Information <br />
                    His & Her Information <br />
                    His&Her Information
                    <br />
                    His/Her Information
                    <br />
                    His & Her Information Inc.
                    <br />
                    His & Her Information Incorporated
                    <br />
                    His & Her Information Co.
                    <br />
                    His & Her Information Company
                    <br />
                    His & Her Information Limited
                  </Typography>
                  <Typography>
                    All of the above names would be read as &apos;HISHERINFORMATION&apos; by the
                    compact program. Using compact names, individuals with the same last name are
                    not necessarily grouped together. They are intermixed with individuals whose
                    last name is similar and with corporate names beginning with similar words.
                  </Typography>
                </>
              ),
            },
            {
              text: (
                <>
                  <Typography marginBottom={2}>
                    Example: For inquiries on individual debtor &apos;ARTHUR BEACH&apos; using
                    Compact Name, type BEACH ARTHUR in the Name field, then press the
                    &quot;Search&quot; button.
                  </Typography>
                  <Typography marginBottom={2}>A Resulting Name List Might Be:</Typography>
                  <Typography marginBottom={2}>
                    Beach Arthur <br />
                    Beach Ball Toys <br />
                    Beachboard Games
                    <br />
                    Beache Fred
                    <br />
                    Beach Tom
                  </Typography>
                  <Typography>
                    The entries on the name list screen for actual and compact names will look
                    identical, they may just be in a different order. The title at the top of this
                    screen will indicate if you are on the actual name list screen or the compact
                    name list screen.
                  </Typography>
                </>
              ),
            },
          ],
        },
      ],
    },
    {
      text: 'Viewing Images Of UCC Filings:',
      children: [
        {
          text: 'From the UCC filing detail screen, you may display, images of UCC filings filed since January 1997. To display an image, click on the 12 digit document number displayed in the Document Images section of the detail screen.',
        },
      ],
    },
  ];

  const renderList = (data: ListItem[], level: number): React.ReactNode => {
    return (
      <ul
        style={{
          listStyleType: level === 1 ? 'disc' : level === 2 ? 'circle' : 'square',
          marginTop: theme.convert.pxToRem(10),
          marginBottom: theme.convert.pxToRem(10),
        }}
      >
        {data.map((item, index) => (
          <li
            key={index}
            style={{
              marginTop: theme.convert.pxToRem(10),
              marginBottom: theme.convert.pxToRem(10),
            }}
          >
            {item.text}
            {item.children && renderList(item.children, level + 1)}
          </li>
        ))}
      </ul>
    );
  };

  const accordions = [
    {
      id: 0,
      title: 'What is Florida Secured Transaction Registry?',
      content: (
        <>
          <Typography marginBottom={2}>
            The &quot;Florida Secured Transaction Registry&quot; is a centralized{' '}
            <Typography component="span" fontWeight={600}>
              Uniform Commercial Code (UCC)
            </Typography>{' '}
            filing and retrieval system containing initial financing statements, amendments,
            assignments, and other UCC filings as authorized by Florida Statutes Chapter 679.
          </Typography>
          <Typography marginBottom={2}>
            <Typography component="span" fontWeight={600}>
              FloridaUCC LLC,
            </Typography>{' '}
            is the contract-vendor for the Florida Secretary of State that provides for the
            organization and maintenance of the Florida Secured Transaction Registry and is duly
            authorized to accept filings for the Florida Secured Transaction Registry.
          </Typography>
          <Typography marginBottom={2}>
            This website provides public access to the Florida Secured Transaction Registry for the
            review of filed documents. The public can also access approved filing forms and
            information on procedures for submitting UCC filing documents by mail, fax, walk-in
            service or by using online filing functionality.
          </Typography>
          <Typography marginBottom={2}>
            Please browse this website to find information on forms and filing procedures, fees,
            data downloads, and walk-in services offered by FloridaUCC LLC.
          </Typography>
          <Typography>
            <Typography component="span" fontWeight={600}>
              Note:
            </Typography>{' '}
            This office cannot provide legal advice or interpretation of the law. Please consult an
            attorney for advice on how to make your filing legally effective.
          </Typography>
        </>
      ),
    },
    {
      id: 1,
      title: 'What does UCC stand for?',
      content: 'UCC stands for Uniform Commercial Code.',
    },
    {
      id: 2,
      title: 'How can I get UCC-1 or UCC-3 forms?',
      content: (
        <Typography component="p">
          Click on the <StyledInheritLink to={formsPageURL}>Forms</StyledInheritLink> option in the
          menu at the top of this website. This is the only option for obtaining forms provided by
          FLORIDAUCC, LLC.
        </Typography>
      ),
    },
    {
      id: 3,
      title: 'What are the UCC filing fees?',
      content: (
        <Typography component="span">
          Click on the <StyledInheritLink to={feesPageURL}>Fees</StyledInheritLink> option in the
          menu at the top of this website.
        </Typography>
      ),
    },
    {
      id: 4,
      title:
        'Now that the Department of State, Division of Corporations has privatized the Florida Secured Transaction Registry, to whom should I make my check payable?',
      content: 'Make checks payable to FLORIDAUCC, LLC or Florida Department of State.',
    },
    {
      id: 5,
      title: 'Should I include a stamped, self-addressed envelope with my processing request?',
      content:
        'No. Please do not include any type of return envelope (self-addressed envelopes, overnight mail service envelopes) with your processing request. While FLORIDAUCC, LLC will generate an acknowledgment in response to every processing request, these acknowledgments will not be returned using envelopes supplied by the requester.',
    },
    {
      id: 6,
      title:
        'Should I include additional copies of my filing request when I submit the request to FLORIDAUCC, LLC?',
      content: 'No. Please only include the UCC form and any attachments for processing.',
    },
    {
      id: 7,
      title: 'Where do I send my filing?',
      content: (
        <>
          <Typography fontWeight={600}>
            Mailing address for UCC Filings and Correspondence:
          </Typography>
          <Typography marginBottom={2}>
            FLORIDAUCC, LLC <br />
            PO Box 5588 <br />
            Tallahassee, FL 32314
          </Typography>
          <Typography fontWeight={600}>Walk In Location:</Typography>
          <Typography marginBottom={2}>
            FLORIDAUCC, LLC <br />
            2002 Old St. Augustine Road, Bldg. D <br />
            Tallahassee, FL 32301
          </Typography>
          <Typography fontWeight={600}>Overnight Deliveries:</Typography>
          <Typography>
            FLORIDAUCC, LLC <br />
            Care of: Image API, LLC. <br />
            2002 Old St. Augustine Road, Bldg. D <br />
            Tallahassee, FL 32301
          </Typography>
        </>
      ),
    },
    {
      id: 8,
      title: 'How long does it take for my UCC Document to be processed?',
      content: (
        <Typography>
          The UCC filings submitted to the filing office are posted to the Florida Secured
          Transaction Registry as soon as processing is complete. The filing office has 3 business
          days to complete processing which walk-ins, mail, fax and online filings.
        </Typography>
      ),
    },
    {
      id: 9,
      title: 'How do I do my own search on the website?',
      content: (
        <Typography>
          Click on the <StyledInheritLink to={uccSearchPageURL}>Search</StyledInheritLink> option in
          the menu at the top of this website. Upon completing the disclaimer, the Search screen
          will be displayed. Choose one item in the “Select Search Type” box, enter the appropriate
          data in the “Name/Document Number” box, and click on “Search”. The exact name or number,
          or the nearest alphabetic or numeric, will be displayed. Additional help is available on
          the Search screen.
        </Typography>
      ),
    },
    {
      id: 10,
      title: 'How are searches performed on the website?',
      content: (
        <>
          <Typography marginBottom={2}>
            The &quot;Search&quot; option allows the user to access UCC filing information using
            either the document number or the debtor name.
          </Typography>
          <Typography fontWeight={600}>UCC Filings</Typography>
          <ul style={{ margin: 0 }}>
            <li>
              Uniform Commercial Code filings are filings which register the secured party&apos;s
              interest in a loan secured by non-titled property.
            </li>
          </ul>
          <Typography fontWeight={600} marginY={2}>
            UCC Filing Inquiry Functions Explanations/Examples
          </Typography>
          <Typography>
            There are several search options available. Below is a listing of those options as well
            as a description of their usage:
          </Typography>
          {renderList(inquiryFunctionsExplanationsAndExamples, 1)}
        </>
      ),
    },
    {
      id: 11,
      title: 'What copies of UCC documents can I get from this website?',
      content:
        'TIFF images of Florida UCC filings can be downloaded from this website for all filings from 1997 to present. To view the document you must have an image viewer supporting TIFF formats installed on your PC.',
    },
    {
      id: 12,
      title: 'How do I get a copy of a UCC document that is not available from this website?',
      content: (
        <>
          <Typography marginBottom={2}>
            Copies of UCC documents filed prior to 1997 must be requested by UCC file number from
            FLORIDAUCC, LLC. Please send requests to the following address:
          </Typography>

          <Typography>FLORIDAUCC, LLC</Typography>
          <Typography>P.O. Box 5588</Typography>
          <Typography> Tallahassee, FL 32314</Typography>

          <Typography marginTop={2}>
            The cost for printing documents is $1.00 per page. You will be notified of the total
            number of pages printed in response to your request, and the cost of your request.
            Checks for requests should be made payable to FLORIDAUCC, LLC.
          </Typography>
        </>
      ),
    },
    {
      id: 13,
      title: 'Do the UCC filings reflect Tax Liens also?',
      content: (
        <Typography>
          No, Tax Liens are not included with UCC filing information on the{' '}
          <StyledInheritLink to={'https://www.floridaucc.com/'}>
            www.floridaucc.com
          </StyledInheritLink>{' '}
          website. Information on Tax Liens is maintained at the Department of State, Division of
          Corporations.
        </Typography>
      ),
    },
    {
      id: 14,
      title: 'Having trouble viewing the website?',
      content: (
        <>
          <Typography marginBottom={2}>
            Google Chrome, Firefox or Edge are recommended for best viewing experience.
          </Typography>
          <Typography>IE10 Users</Typography>
          <ol style={{ margin: 0 }}>
            <li>Right click in the blank area at the top of your Internet Explorer Window.</li>
            <li>If the Menu Bar option is unchecked, Select Menu Bar.</li>
            <li>
              From the Menu Bar, select{' '}
              <Typography component="span" fontWeight={600}>
                Tools --&gt; Compatibility View Settings.
              </Typography>
            </li>
            <li>
              Under Add this website, enter:{' '}
              <StyledInheritLink to={'https://www.floridaucc.com/'}>
                floridaucc.com
              </StyledInheritLink>
            </li>
            <li>
              Click{' '}
              <Typography component="span" fontWeight={600}>
                Add.
              </Typography>
            </li>
            <li>
              Click{' '}
              <Typography component="span" fontWeight={600}>
                Close.
              </Typography>
            </li>
          </ol>
        </>
      ),
    },
    {
      id: 15,
      title: 'Who is the Custodian of Public Records?',
      content: (
        <>
          <Typography component="span" fontWeight={600}>
            Contact Information for Custodian of Public Records:
          </Typography>
          <Typography>Aaron Hall, Custodian of Public Records</Typography>
          <Typography>2002 Old St. Augustine Rd. Bldg. D</Typography>
          <Typography marginBottom={2}>Tallahassee, FL 32301</Typography>

          <Typography>
            Email:{' '}
            <StyledInheritLink to={'mailto:RecordsCustodian@FloridaUCC.com'}>
              RecordsCustodian@FloridaUCC.com
            </StyledInheritLink>
          </Typography>
          <Typography>Phone: 850.222.8526</Typography>
        </>
      ),
    },
  ];

  return <ListAccordions accordions={accordions} initiallyOpen={[0]} />;
};

export default FaqAccordionsHelp;
