import React, { useMemo } from 'react';
import { Button, Divider, useTheme } from '@mui/material';
import { Box, Container, List, Toolbar, Typography } from '@mui/material/';

import { StyledNavLink } from './StyledNavLink';
import { StyledListItem } from './StyledListItem';
import { StyledAppBar } from './StyledAppBar';
import { ReactComponent as OpenHamburger } from '../../../assets/icons/open-hamburger.svg';
import { ReactComponent as CloseHamburger } from '../../../assets/icons/close-hamburger.svg';
import LoggedInUserMenu from '../logged-in-user-menu';
import { StyledDrawer } from './StyledDrawer';
import { filing, login, homePageURL, uccSearchPageURL } from '../../constants/routes';
import { UCCHomeLogo } from './UCCHomeLogo';
import { InformationMenu } from './InformationMenu';
import { useAuth } from '../../context';
import { AuthedState } from '../../context/types';
import { InformationMenuSmallScreen } from './InformationMenuSmallScreen';
import LoggedInUserMenuSmallScreen from '../logged-in-user-menu/LoggedInUserMenuSmallScreen';
import { errorMainColor, primaryLightBlueColor } from '../../constants/colors';
import { Link } from 'react-router-dom';

const Header = () => {
  const theme = useTheme();
  const { authed } = useAuth();
  const [isDrawerOpen, setisDrawerOpen] = React.useState<boolean>(false);

  const headerItemsConfig: { text: string; url: string }[] = [
    { text: 'Home', url: homePageURL },
    { text: 'UCC Search', url: uccSearchPageURL },
    { text: 'Online Filing', url: authed === AuthedState.NOT_AUTHED ? login : filing },
  ];

  const handleOpenDrawer = () => {
    setisDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setisDrawerOpen(false);
  };

  const environment: string = useMemo(() => {
    const hostname = window.location.hostname;

    if (hostname.includes('test')) {
      return 'test';
    } else if (hostname.includes('dev')) {
      return 'dev';
    } else if (hostname.includes('stage')) {
      return 'test';
    } else if (hostname.includes('localhost')) {
      return 'local';
    } else {
      return 'production';
    }
  }, []);

  return (
    <StyledAppBar>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '100%',
        }}
      >
        {environment !== 'production' && (
          <Typography
            fontSize={{
              xs: theme.typography.pxToRem(12),
              md: theme.typography.pxToRem(12),
              lg: theme.typography.pxToRem(14),
            }}
            style={{ color: primaryLightBlueColor, backgroundColor: 'red' }}
            fontWeight={600}
            marginLeft={-3}
            marginRight={-3}
            paddingX={5}
            whiteSpace={'nowrap'}
          >
            This is a {environment.charAt(0).toUpperCase() + environment.slice(1)} System. To File
            UCCs, go{' '}
            <Link color={errorMainColor} to={'https://floridaucc.com/'}>
              here.
            </Link>
          </Typography>
        )}
        <Toolbar disableGutters>
          <Box
            sx={{
              display: { xs: 'none', lg: 'flex' },
              paddingLeft: theme.convert.pxToRem(4),
            }}
          >
            <UCCHomeLogo />
          </Box>
          <Box
            sx={{
              display: { xs: 'flex', lg: 'none' },
              marginLeft: theme.convert.pxToRem(-7),
            }}
          >
            <Button
              sx={{ paddingX: 1, minWidth: 0 }}
              onClick={isDrawerOpen ? handleCloseDrawer : handleOpenDrawer}
            >
              {isDrawerOpen ? <CloseHamburger /> : <OpenHamburger />}
            </Button>
          </Box>
          <Box
            sx={{
              display: { xs: 'flex', lg: 'none' },
            }}
          >
            <StyledDrawer
              PaperProps={{
                elevation: 0,
              }}
              ModalProps={{
                BackdropProps: {
                  invisible: true,
                },
              }}
              variant="persistent"
              anchor="left"
              open={isDrawerOpen}
              onClose={handleCloseDrawer}
            >
              <List onClick={handleCloseDrawer} style={{ padding: theme.convert.pxToRem(0) }}>
                {headerItemsConfig.map(({ url, text }) => (
                  <StyledListItem key={`drawer_${url}`}>
                    <StyledNavLink tabIndex={-1} to={url}>
                      <Button
                        sx={{
                          minWidth: 0,
                          padding: 0,
                          '&:hover': {
                            textDecoration: 'none',
                          },
                        }}
                      >
                        <Typography variant="h4" paddingBottom={theme.convert.pxToRem(2)}>
                          {text}
                        </Typography>
                      </Button>
                    </StyledNavLink>
                  </StyledListItem>
                ))}
              </List>
              <StyledListItem>
                <Box>
                  <InformationMenuSmallScreen handleCloseDrawer={handleCloseDrawer} />
                </Box>
              </StyledListItem>
              <Divider />
              <StyledListItem>
                <LoggedInUserMenuSmallScreen handleCloseDrawer={handleCloseDrawer} />
              </StyledListItem>
            </StyledDrawer>
          </Box>
          <Box
            onClick={handleCloseDrawer}
            sx={{
              display: { xs: 'flex', lg: 'none' },
              flexGrow: 1,
              justifyContent: { xs: 'center', md: 'start' },
              alignItems: 'center',
              marginLeft: {
                xs: isDrawerOpen ? theme.convert.pxToRem(8) : 0,
                md: isDrawerOpen ? theme.convert.pxToRem(48) : theme.convert.pxToRem(40),
              },
            }}
          >
            <UCCHomeLogo isSmall />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'none', lg: 'flex' },
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: theme.convert.pxToRem(50),
                maxWidth: '55%',
                marginRight: theme.convert.pxToRem(30),
                marginLeft: { xl: '7%' },
              }}
            >
              {headerItemsConfig.map(({ text, url }) => (
                <StyledNavLink tabIndex={-1} to={url} key={url}>
                  <Button
                    sx={{
                      minWidth: 0,
                      padding: 0,
                      '&:hover': {
                        textDecoration: 'none',
                      },
                    }}
                  >
                    <Typography variant="h4">{text}</Typography>
                  </Button>
                </StyledNavLink>
              ))}
              <InformationMenu />
            </Box>
            <LoggedInUserMenu />
          </Box>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Header;
