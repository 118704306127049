import { FC } from 'react';
import SearchGridWhiteHeading from './SearchGridWhiteHeading';
import { StyledNavigationTypography, StyledTableHeadingGrid } from './StyledComponents';
import { StyledNavLink } from '../../../common/components/header/StyledNavLink';
import { uccSearchDetailsFilingHistoryURL } from '../../../common/constants/routes';
import { FileDetailsInterface } from '../types';
import { useSearchPageQueryParams } from '../useSearchPageQueryParams';

const FilingHistory: FC<{ fileData: FileDetailsInterface }> = ({ fileData }) => {
  const { filingEvents, ucc1Number } = fileData;
  const { text, searchOption, rn } = useSearchPageQueryParams();
  return (
    <div>
      <SearchGridWhiteHeading
        title="Filing Events"
        description="Events Filed"
        result={filingEvents}
      />
      <StyledTableHeadingGrid item container display={'flex'} justifyContent={'end'}>
        {filingEvents > 0 && (
          <StyledNavLink
            to={
              uccSearchDetailsFilingHistoryURL +
              `?searchOption=${searchOption}&text=${text}&uccNumber=${ucc1Number}` +
              (searchOption !== 'DocumentNumber' ? `&rn=${rn}` : '') +
              `&page=1`
            }
          >
            <StyledNavigationTypography variant="body2">
              View Filing History
            </StyledNavigationTypography>
          </StyledNavLink>
        )}
      </StyledTableHeadingGrid>
    </div>
  );
};

export default FilingHistory;
