import { getFileDetails } from '../search.services';
import { FileDetailsInterface, UCCSearchOption } from '../types';
import { useSearchPageQueryParams } from '../useSearchPageQueryParams';
import { useEffect, useMemo, useState } from 'react';
import { uccSearchDetailsPageURL, uccSearchPageURL } from '../../../common/constants/routes';
import { useSnackbar } from '../../../common/notification';
import { GenericErrorMessage } from '../../../common/constants/features-constants';

export const useDetailsPage = () => {
  const { uccNumber, searchOption, rn, getUrl } = useSearchPageQueryParams();
  const { Snack } = useSnackbar();
  const [fileData, setFileData] = useState<FileDetailsInterface>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const res = await getFileDetails(
          searchOption ?? UCCSearchOption.DocumentNumber,
          uccNumber ?? undefined,
          rn
        );
        if (!res.notOk) {
          setFileData(res.payload);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        Snack.error(GenericErrorMessage);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rn, searchOption]);

  const nextUrl = useMemo(() => {
    if (Number(fileData?.nextRowNumber) === Number(rn)) {
      return null;
    }
    return getUrl(uccSearchDetailsPageURL, { rn: fileData?.nextRowNumber?.toString() });
  }, [getUrl, fileData?.nextRowNumber, rn]);

  const prevUrl = useMemo(() => {
    if (Number(fileData?.prevRowNumber) === Number(rn)) {
      return null;
    }

    return getUrl(uccSearchDetailsPageURL, { rn: fileData?.prevRowNumber?.toString() });
  }, [getUrl, fileData?.prevRowNumber, rn]);
  const backUrl = useMemo(() => getUrl(uccSearchPageURL, {}), [getUrl]);

  const canHaveNavigation = useMemo(
    () => searchOption !== UCCSearchOption.DocumentNumber,
    [searchOption]
  );

  return {
    fileData,
    isLoading,
    nextUrl,
    prevUrl,
    backUrl,
    canHaveNavigation,
    searchOption,
  };
};
