import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DebtorSearchResponse, SearchOption, UCCSearchOption } from './types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getFileDetails,
  getFilingsCompletedThroughDate,
  getSearchOptions,
  getSearchResults,
} from './search.services';
import { useSearchDataFetch } from './useSearchDataFetch';
import { uccSearchDetailsPageURL, uccSearchPageURL } from '../../common/constants/routes';
import { ResponseMessageType } from '../shared/types';
import { useSearchPageQueryParams } from './useSearchPageQueryParams';
import { useNavigate } from 'react-router-dom';

const schema = yup.object().shape({
  option: yup.string().required('Search type is required.'),
  keyword: yup.string().when('option', {
    is: UCCSearchOption.DocumentNumber,
    then: (schema) =>
      schema
        .required('UCC number is required.')
        .max(12, 'UCC number cannot be more than 12 characters.'),
    otherwise: (schema) =>
      schema.required('Name is required.').max(100, 'Name cannot be more than 100 characters.'),
  }),
});

type FormState = yup.InferType<typeof schema>;

const mockSearchResponseNoDebtors = {
  status: 'OK',
  notOk: false,
  messages: [],
  payload: {
    debtors: [],
    nextRowNumber: 123,
    previousRowNumber: 124,
  },
  messageSummary: '',
  friendlyMessageSummary: '',
} as any as ResponseMessageType<DebtorSearchResponse>;

export const useSearchPage = () => {
  const navigate = useNavigate();
  const { data: options, isFetching: isFetchingOptions } =
    useSearchDataFetch<SearchOption[]>(getSearchOptions);
  const { data: throughDate, isFetching: isFetchingThroughDate } = useSearchDataFetch<string>(
    getFilingsCompletedThroughDate
  );
  const hasFinishedInitialFetches = !isFetchingOptions && !isFetchingThroughDate;
  const { rn, updateURL, getUrl, searchOption, text } = useSearchPageQueryParams();

  const form = useForm<FormState>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: { keyword: text ?? '', option: searchOption ?? '' },
  });

  const [lastKeywordWithNoResults, setLastKeywordWithNoResults] = useState('');

  const doSearch = useCallback(
    async (skipRN?: boolean) => {
      const keyword = form.getValues().keyword;
      const option = form.getValues().option ?? UCCSearchOption.FiledCompactDebtorNameList;
      const rowNumber = !skipRN && rn ? parseInt(rn) : undefined;

      if (skipRN) {
        updateURL({ text: keyword, searchOption: option });
      }

      if (option !== UCCSearchOption.DocumentNumber) {
        return getSearchResults({
          rowNumber,
          text: skipRN ? keyword : text ?? '',
          searchOption: skipRN
            ? option
            : searchOption ?? UCCSearchOption.FiledCompactDebtorNameList,
        });
      }

      try {
        await getFileDetails(UCCSearchOption.DocumentNumber, keyword);
        navigate(
          getUrl(uccSearchDetailsPageURL, {
            searchOption: option,
            text: keyword,
            uccNumber: keyword,
          })
        );
        return mockSearchResponseNoDebtors;
      } catch (e) {
        setLastKeywordWithNoResults(keyword);
        return new Promise<ResponseMessageType<DebtorSearchResponse>>((r) =>
          r(mockSearchResponseNoDebtors)
        );
      }
    },
    [form, getUrl, navigate, rn, searchOption, text, updateURL]
  );

  const {
    doFetch: search,
    data: searchResults,
    isFetching: isFetchingSearchResults,
  } = useSearchDataFetch<DebtorSearchResponse, boolean>(doSearch, false, true);

  useEffect(() => {
    if (
      hasFinishedInitialFetches &&
      !isFetchingSearchResults &&
      searchOption !== UCCSearchOption.DocumentNumber &&
      rn
    ) {
      search();
    }
  }, [hasFinishedInitialFetches, search, rn]);

  useEffect(() => {
    if (!options?.length || form.getValues('option')) return;

    form.setValue('option', options[0].id);
  }, [form, options]);

  const handleSubmit = form.handleSubmit(async () => {
    if (!isFetchingSearchResults) {
      await search(true);
    }
  });

  const keyword = form.watch('keyword');
  const option = form.watch('option');
  const nextUrl = useMemo(() => {
    if (
      !searchResults?.nextRowNumber ||
      searchResults?.nextRowNumber ===
        (searchResults.debtors[searchResults.debtors.length - 1]?.rowNumber ?? -1)
    )
      return null;

    return getUrl(uccSearchPageURL, { rn: searchResults?.nextRowNumber?.toString() });
  }, [getUrl, searchResults?.debtors, searchResults?.nextRowNumber]);
  const prevUrl = useMemo(() => {
    if (
      !searchResults?.previousRowNumber ||
      (searchResults?.previousRowNumber === searchResults.debtors[0]?.rowNumber ?? -1)
    )
      return null;

    return getUrl(uccSearchPageURL, { rn: searchResults?.previousRowNumber?.toString() });
  }, [getUrl, searchResults?.debtors, searchResults?.previousRowNumber]);

  return {
    hasFinishedInitialFetches,
    throughDate,
    options,
    ...form,
    getUrl,
    nextUrl,
    prevUrl,
    option,
    keyword,
    handleSubmit,
    searchResults,
    isFetchingSearchResults,
    lastKeywordWithNoResults,
  };
};
