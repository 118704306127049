import { useSearchParams } from 'react-router-dom';
import { useCallback } from 'react';

type URLParams = {
  rn?: string;
  text?: string;
  uccNumber?: string;
  searchOption?: string;
};

export const useSearchPageQueryParams = () => {
  const [params, setParams] = useSearchParams();

  const updateURL = useCallback(
    (overrides: URLParams) => {
      setParams({ ...overrides }, { replace: true });
    },
    [setParams]
  );

  const getUrl = useCallback(
    (page: string, paramOverrides: Record<string, string | undefined | null>) => {
      const redirectParameters = new URLSearchParams(params);
      Object.entries(paramOverrides).forEach(([key, value]) => {
        if (value === null) {
          redirectParameters.delete(key);
        } else if (value) {
          redirectParameters.set(key, value);
        }
      });

      return `${page}?${redirectParameters.toString()}`;
    },
    [params]
  );

  return {
    getUrl,
    updateURL,
    rn: params.get('rn') ?? undefined,
    text: params.get('text') ?? undefined,
    uccNumber: params.get('uccNumber') ?? undefined,
    searchOption: params.get('searchOption') ?? undefined,
    pageNumber: params.get('page') ?? undefined,
  };
};
